import { Injectable } from '@angular/core';
import { AbstractService } from '@services/abstract/abstract.service';
import { AssetStatsInterface } from '@interfaces/stats/asset-stats.interface'
import { AssetStatsQueryParams } from '@interfaces/stats/asset-stats.params'

@Injectable()
export class AssetStatsService extends AbstractService<
  AssetStatsInterface,
  AssetStatsQueryParams
> {
  protected errorsSection = 'errors.asset-stats';
  protected endPoint = '/stats/assets/%s';
}
