import { Injectable } from '@angular/core';
import { QueryParamsInterface } from '@interfaces/global/query.params.interface';
import { PolicyResponseInterface } from '@interfaces/policy/policy.response.interface';
import { AbstractService } from '@services/abstract/abstract.service';
@Injectable()
export class PoliciesService extends AbstractService<
  PolicyResponseInterface,
  QueryParamsInterface
> {
  protected errorsSection = 'errors.policies';
  protected endPoint = '/policies/';
}
