import { InjectionToken } from '@angular/core';

export enum PMLiterals {
  AUTO_ORIGIN = '🤖 Auto detected parent origin:',
  ERROR_MSG_NO_PINGPONG = '🟥 Message was sent but PING PONG protocol wasn\'t completed',
  ERROR_MSG_NO_SENT_PINGPONG = '🟥 Message NOT sent. PING PONG protocol wasn\'t completed',
  ERROR_NO_ACK = '🟥 Message Sent but ACK not received',
  ERROR_PONG_NOT_COMPLETED = '🟥 PING Sent but PONG not received',
  MSG_ACK_CONFIRMED = '😉 Message sent with ACK confirmed',
  MSG_FROM_PARENT = '🟩 Message received from parent:',
  MSG_SENT_PARENT = '🟨 Message sent to parent:',
  PINGPONG_COMPLETED = '😉 Ping Pong Protocol completed',
  CANT_CREATE_WALLET = '🟥 Can\'t create a new wallet if PING PONG isn\'t completed'
}

export enum PMErrors {
  REQUEST = 'ERROR_REQUEST',
  PONG = 'ERROR_PONG'
}

// Config
export interface PMConfig {
  maxRetries?: number;
  retryPeriod?: number; // in ms
  waitForAck?: boolean;
  waitForReady?: boolean;
  targetOrigin: string; // 'auto' | url origin
  autoConnect?: boolean;
  debug?: boolean;
}

export const PMDefaultConfig: PMConfig = {
  maxRetries: 5,
  retryPeriod: 1000,
  waitForAck: true,
  waitForReady: true,
  targetOrigin: 'auto',
  autoConnect: true,
  debug: true,
};

export const PMConfigParams = new InjectionToken<PMConfig>('PMConfig');

// Status
export enum PMStatus {
  INITIALIZING,
  CONNECTING,
  READY,
  ERROR,
}

export enum PMMessageTypes {
  ACCOUNT_DATA = 'accountData', // EVENTO CUENTA CRIPTODIVISAS (CREAND -> IFRAME)
  DETAILS_DATA = 'detailsData', // EVENTO DETALLES TRANSACCION (IFRAME -> CREAND)
  OPERATION_DATA = 'operationData', // EVENTO COMPRA/VENTA/WITHRDAW/RECEIVE (IFRAME -> CREAND)
  RESIZE_DATA = 'resizeData', // EVENTO REDIMENSION DE LA VENTANA  (IFRAME -> CREAND)
  SEND_DATA = 'sendData', // EVENTO SIN USO (!)
  SCROLL_CHECK = 'scrollCheck', // EVENTO SCROLL INFINITO (CREAND -> IFRAME)
  ACK = 'ack', // CONFIRMACIÓN DEL MENSAJE ENVIADO (CREAND -> IFRAME)
  ERROR = 'error', // EVENTO ERROR (CUALQUIER ERROR POR CUALQUIER PARTE) BIDIRECCIONAL (CREAND <-> IFRAME)
  PING = 'ping', // EVENTO PING (IFRAME -> CREAND)
  PONG = 'pong', // EVENTO PONG (CREAND -> IFRAME)
  NEW_WALLET = 'newWallet', // EVENTO NUEVO WALLET (IFRAME -> CREAND)
  LISTENING = 'listening' // EVENTO REFRESCAR DATOS (CREAND -> IFRAME)
}

export enum PMOperationTypes {
  BUY = 'buy',
  SELL = 'sell',
  WITHDRAWAL = 'withdrawal',
  RECEIVE = 'receive',
}

// PM Events
export interface PMEvent<T = PMOperation> {
  origin: string;
  source: Window;
  data: PMMessage<T>;
}

export interface PMMessage<T> {
  operation: PMMessageTypes;
  data?: T;
}

// PM Operation Messages
export interface PMOperationMessage {
  asset: PMCryptoAsset & { assetIcon: string };
  wallet: PMCryptoWallet;
  brkUserId: string;
  onyzeUserId: string;
  // externalUserId: string; // por analizar.
  operation: PMOperation;
}

export interface PMOperation {
  type?: PMOperationTypes;
  min?: string;
  max?: string;
  comission?: string;
  [key: string]: string;
}

export interface PMCryptoWallet {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  balance: number;
  address: string;
  user: any;
  incomes: string;
}

export interface PMCryptoAsset {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  name?: string;
  symbol?: string;
  rate?: PMCryptoRate;
  marketData?: PMCryptoMarketData;
}

export interface PMCryptoRate {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  EUR?: string;
  USD?: string;
  BTC?: string;
  type?: string;
}

export interface PMCryptoMarketData {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  volume24h?: string;
  percentChange1h?: string;
  percentChange24h?: string;
  percentChange7d?: string;
  circulatingSupply?: string;
  totalSupply?: string;
  maxSupply?: string;
  lastUpdated?: string;
  marketCap?: string;
  marketCapRank?: string;
  fullyDilutedValuation?: string;
  high24h?: string;
  low24h?: string;
  ath?: string;
  athPercentChange?: string;
  athDate?: string;
  atl?: string;
  atlPercentChange?: string;
  atlDate?: string;
  percentChange14d?: string;
  percentChange200d?: string;
  percentChange1y?: string;
}
