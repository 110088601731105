import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
// services
import { AuthHttpInterceptor } from '../auth/auth.http.interceptor';
import { PoliciesService } from './policies.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
})
export class PoliciesServiceModule {
  static forRoot(): ModuleWithProviders<PoliciesServiceModule> {
    return {
      ngModule: PoliciesServiceModule,
      providers: [PoliciesService],
    };
  }
}
