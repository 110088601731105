import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthHttpInterceptor } from '@services/auth/auth.http.interceptor';

// services
import { TransactionsService } from './transactions.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
})
export class TransactionsServiceModule {
  static forRoot(): ModuleWithProviders<TransactionsServiceModule> {
    return {
      ngModule: TransactionsServiceModule,
      providers: [TransactionsService],
    };
  }
}
