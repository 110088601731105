import { Injectable } from '@angular/core';
import { ScheduledQueryParamsInterface } from '@interfaces/scheduled/scheduled.query.params.interface';
import { ScheduledResponseInterface } from '@interfaces/scheduled/scheduled.response.interface';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class ScheduledService extends AbstractService<
  ScheduledResponseInterface,
  ScheduledQueryParamsInterface
> {
  protected errorsSection = 'errors.scheduled';
  protected endPoint = '/schedules/';
}
