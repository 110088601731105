import { Injectable } from '@angular/core';
import { WalletQueryParamsInterface } from '@interfaces/user/wallet/wallet.query.params.interface';
import { WalletResponseInterface } from '@interfaces/user/wallet/wallet.response.interface';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class WalletsService extends AbstractService<
  WalletResponseInterface,
  WalletQueryParamsInterface
> {
  protected errorsSection = 'errors.wallets';
  protected endPoint = '/users/%s/wallets/';
}
