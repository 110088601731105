import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { ErrorInterface } from '@interfaces/errors/error.interface';
import { Config, NavController, Platform } from '@ionic/angular';
import { IonicToasterService } from '@ionServices/toaster/ionic-toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import { BasicObservableService } from '@services/basic-observable/basic-observable.service';
import { ConfigsService } from '@services/config/config.service';
import {
  ErrorServiceInterface,
  ErrorsService,
} from '@services/errors/errors.service';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { PMErrors, PMMessageTypes } from '@services/pm-service/pm.models';
import { PMService } from '@services/pm-service/pm.service';
import { CreandAccount } from '@shared/components/creand-account/creand-account.component';
import { Big } from 'big.js';
import { Subscription } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { vsprintf } from 'sprintf-js';
import { Themes } from '@interfaces/configurations/theme.enum'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  private subErrors: Subscription;

  languages = environment.languages;

  getPreferredScheme = () => window?.matchMedia?.('(prefers-color-scheme:dark)')?.matches ? 'dark' : 'light';

  private subTheme: Subscription
  private lastTheme: string;

  constructor(
    private readonly configService: ConfigsService, // Este service debe declarar al inicio de la aplicacion aunque no se use (contructor)
    private readonly PMService: PMService,
    private readonly authService: AuthService,
    private readonly errorsService: ErrorsService,
    private readonly config: Config,
    private readonly translate: TranslateService,
    private readonly storage: LocalStorageService,
    private readonly toasterIonic: IonicToasterService,
    private readonly platform: Platform,
    private readonly bOBService: BasicObservableService,
    private readonly navCtrl: NavController,
    private readonly router: Router,
  ) {
    Big.DP = 10;
    Big.RM = 0;
  }

  async ngOnInit(): Promise<void> {

    /* 
    * De los parametros obtenemos el tema y comprobamos si estamos entrando al sso.
    * El check del sso aquí es necesario porque segun el enrutado este componente carga antes
    * que el componente de sso provocando que se pueda acceder a un usuario anterior.
    */
    this.URIParamsHandler();

    await this.initializeApp();
    await this.platform.ready();
    registerLocaleData(localeEs, 'es');
  }

  private parseUrl(url: string) {
    const parsedUrl = new URL(`https://fakedomain${url}`);
    const urlParams = parsedUrl.searchParams;
    return {
      sso: urlParams.get('sso'),
      theme: urlParams.get('theme'),
      lang: urlParams.get('lang'),
    }
  }

  private URIParamsHandler() {
    this.router.events
    .pipe(
      filter((event) => event instanceof NavigationStart),
      map((event) => (event as NavigationStart).url),
      take(1)
    )
    .subscribe(url => {
      const urlParsed = this.parseUrl(url);
      
      if(urlParsed.theme !== this.lastTheme) {
        this.lastTheme = urlParsed.theme || 'auto'
        this.setTheme(this.lastTheme)
      }
      
      // Si no vamos a entrar al SSO cargamos tokens
      // Si entramos al SSO, el SSO se encarga de limpiar los tokens antes del refresh.
      if(!url.includes('/sso?')) {
        this.authService.loadTokens();
      }
    })
  }

  private setTheme(theme: string) {
    if(theme === 'auto') theme = this.getPreferredScheme();
    theme = Themes[theme] || 'auto';
    document.body.classList.add(theme)
    this.configService.update({ theme })
  }

  private initPMService(): void {

    this.PMService
      .listen<CreandAccount>(PMMessageTypes.ACCOUNT_DATA)
      .pipe(map(({data: operation}) => operation.data))
      .subscribe(account => {
        this.updateAccount(account)
      })

    this.PMService.connect().subscribe();
  }

  private updateAccount(account: CreandAccount): void {
    this.bOBService.update({
      id: 'creand-account',
      payload: account
    }, 'creand-account')
  }

  async initializeTranslate() {
    this.translate.addLangs(this.languages);
    const currentLang = this.getCurrentLang();
    this.translate.setDefaultLang(currentLang);
    this.translate.use(currentLang);
    this.storage.create({
      key: 'language',
      value: this.translate.store.defaultLang.toLocaleUpperCase(),
    });
  }

  getCurrentLang(): string {
    const defaultLang = 'es';
    const browserLang = this.translate.getBrowserLang();
    return (
      this.languages.find((language) => language === browserLang) ?? defaultLang
    );
  }

  async initializeApp() {
    this.initPMService();
    await this.initializeTranslate();

    this.config.set(
      'backButtonIcon',
      '/assets/extern/custom-ion-icons/ic_back_header.svg',
    );

    this.subErrors = this.errorsService
      .getObservable('errors')
      .pipe(
        filter((errors) => errors?.length > 0),
        map((errors) => errors[errors.length - 1]),
      )
      .subscribe((error: ErrorServiceInterface) => this.onError(error));
  }

  protected onError(error: ErrorServiceInterface): void {
    const errors: ErrorInterface[] = error.payload?.error?.errors;

    const ignoreErrorSections = [
      'errors.stats-balances.standard',
      'errors.operations.otp',
      'errors.wallets.detail'
    ];

    if (ignoreErrorSections.includes(error.section)) {
      return;
    }

    if (!errors) {
      console.error('Something fails', {
        error,
        message: error.payload.error.message,
        code: error.payload.status,
      });

      // Enviamos el error porque falló una peticion
      this.PMService.sendError(PMErrors.REQUEST);

      this.toasterIonic.presentToastWithOptions(
        this.translate.instant('admin.otp.operationFailed', {}),
        'error-toastr',
      );

      // Navegamos a la pagina de sesión caducada.
      this.navCtrl.navigateRoot(['/error'])
    } else {
      errors.forEach((singleError) => {
        // Translate
        const key = singleError.message;
        const msg = vsprintf(key, singleError.values);
        this.toasterIonic.presentToastWithOptions(msg, 'error-toastr');
      });
    }

    this.errorsService.remove(error.id);
  }

  ngOnDestroy(): void {
    this.subErrors.unsubscribe();
    this.subTheme.unsubscribe();
  }
}
