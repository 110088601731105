import { Injectable } from '@angular/core';
import { QueryParamsInterface } from '@interfaces/global/query.params.interface';
import { AbstractService } from '@services/abstract/abstract.service';

import { FindAssetPurchasesConfigInterface } from '@interfaces/configurations/find-asset-purchases-config.interface';

import { FindAssetWithdrawalsConfigInterface } from '@interfaces/configurations/find-asset-withdrawals-config.interface';

@Injectable()
export class ConfigurationsPurchaseService extends AbstractService<
  FindAssetPurchasesConfigInterface,
  QueryParamsInterface
> {
  protected errorsSection = 'errors.configurations';
  protected endPoint = '/configurations/assets/%s/purchases';
}

@Injectable()
export class ConfigurationsWithdrawalService extends AbstractService<
  FindAssetWithdrawalsConfigInterface,
  QueryParamsInterface
> {
  protected errorsSection = 'errors.configurations';
  protected endPoint = '/configurations/assets/%s/withdrawals';
}
