import { Injectable } from '@angular/core';
import { AssetResponseInterface } from '@interfaces/asset/asset.response.interface';
import { BasicObservableService } from '@services/basic-observable/basic-observable.service';
import {
  ConfigurationsPurchaseService,
  ConfigurationsWithdrawalService,
} from '@services/configurations/configurations.service';
import {
  PMOperationMessage,
  PMOperationTypes,
} from '@services/pm-service/pm.models';
import { ExtendedWallet } from '@shared/components/wallet-item-list/wallet-item-list.component';
import { filter, first, lastValueFrom, map } from 'rxjs';

@Injectable()
export class OperationsCreatorService {
  private configPurchaseSection = 'configurations-purchase';
  private configWithdrawSection = 'configurations-withdraw';

  wallets$ = this.bOBService.getObservable('actual-page').pipe(
    filter((pages) => !!pages),
    map((page) => page[page.length - 1]?.payload?.wallet),
    first(),
  );

  configurationPurchase$ = this.configurationsPurchaseService
    .getObservable(this.configPurchaseSection)
    .pipe(
      filter((config) => !!config),
      first(),
    );

  configurationWithdraw$ = this.configurationsWithdrawService
    .getObservable(this.configWithdrawSection)
    .pipe(
      filter((config) => !!config),
      first(),
    );

  constructor(
    private configurationsPurchaseService: ConfigurationsPurchaseService,
    private configurationsWithdrawService: ConfigurationsWithdrawalService,
    private bOBService: BasicObservableService,
  ) {}

  private async getComissions(
    type: PMOperationTypes,
    assetId: string,
  ): Promise<any> {
    const _type = type === PMOperationTypes.WITHDRAWAL ? type : 'default';

    const configurationsService = {
      [PMOperationTypes.WITHDRAWAL]: this.configurationsWithdrawService,
      default: this.configurationsPurchaseService,
    };

    const serviceListener$ = {
      [PMOperationTypes.WITHDRAWAL]: this.configurationWithdraw$,
      default: this.configurationPurchase$,
    };

    const section = {
      [PMOperationTypes.WITHDRAWAL]: this.configWithdrawSection,
      default: this.configPurchaseSection,
    };

    const configService = configurationsService[_type];
    configService.getAll('', section[_type], [assetId]);
    const comissions = await lastValueFrom(serviceListener$[_type] as any);
    return comissions as any;
    /* 
    const mappedComissions = {
      [PMOperationTypes.TRANSFER]: (comissions: AssetWithdrawalsConfigInterface) => ({
        min: comissions.minimum,
        max: comissions.decimals,
        comission: comissions.fee
      }),
      [PMOperationTypes.BUY]: (comissions: AssetPurchasesConfigInterface) => ({
        min: comissions.assetMinLimit,
        max: comissions.assetMaxLimit,
        comissions: comissions.buyFee
      }),
      [PMOperationTypes.SELL]: (comissions: AssetPurchasesConfigInterface) => ({
        min: comissions.assetMinLimit,
        max: comissions.assetMaxLimit,
        comissions: comissions.sellFee
      }), 
    }
    return mappedComissions[type](comissions as any)
    */
  }

  async createOperation(
    type: PMOperationTypes,
    wallet: ExtendedWallet | null,
    asset: AssetResponseInterface,
    props: any = {}
  ): Promise<PMOperationMessage> {
    const user = wallet.user;
    // GET COMISSIONS
    
    let comissions;
    if(type !== PMOperationTypes.RECEIVE){
      comissions = await this.getComissions(type, asset.id);
      delete comissions?.asset;
    }

    const operation: PMOperationMessage = {
      asset: {
        ...asset,
        assetIcon: 'assets/crypto/' + asset.symbol + '.png'
      },
      wallet: {
        id: wallet?.id,
        createdAt: wallet?.createdAt,
        updatedAt: wallet?.updatedAt,
        balance: Number(wallet?.balance),
        address: wallet?.address,
        incomes: wallet?.incomes,
        user,
      },
      brkUserId: user.id,
      onyzeUserId: user.caasUser,
      operation: {
        type,
        ...comissions,
        ...props
      },
    };

    return operation;
  }
}
