import { Injectable } from '@angular/core';
import { AssetQueryParamsInterface } from '@interfaces/asset/asset.query.params.interface';
import { AssetResponseInterface } from '@interfaces/asset/asset.response.interface';
import { AssetRateTypes } from '@interfaces/asset/enums/asset-rate-types.enum';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class AssetsService extends AbstractService<
  AssetResponseInterface,
  AssetQueryParamsInterface
> {
  protected errorsSection = 'errors.assets';
  protected endPoint = '/assets/';

  public getRate(hash: string, operation?: AssetRateTypes) {
    const baseEndpointUrl = `${this.baseUrl}${this.endPoint}${hash}/rate`;

    const endpointUrl = operation
      ? `${baseEndpointUrl}?type=${operation}`
      : baseEndpointUrl;

    const section = 'rate';
    this.cleanSection(section);

    this.queryGetOne(section, endpointUrl);
  }
}
