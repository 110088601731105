import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { BasicObservableModule } from '../basic-observable/basic-observable.module';
import { ActionObservableService } from './action-observable.service';

@NgModule({
  imports: [CommonModule, BasicObservableModule],
})
export class ActionObservableModule {
  static forRoot(): ModuleWithProviders<ActionObservableModule> {
    return {
      ngModule: ActionObservableModule,
      providers: [ActionObservableService],
    };
  }
}
