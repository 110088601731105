import { Injectable } from '@angular/core';
import { WalletResponseInterface } from '@interfaces/user/wallet/wallet.response.interface';
import { AuthService } from '@services/auth/auth.service';
import {
  PMLiterals,
  PMMessageTypes,
  PMStatus,
} from '@services/pm-service/pm.models';
import { PMService } from '@services/pm-service/pm.service';
import { WalletsService } from '@services/users/wallets/wallets.service';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  filter,
  first,
  map,
  of,
  startWith,
  switchMap,
  tap,
} from 'rxjs';

enum WalletSections {
  CREATE_WALLET = 'creand-create-wallet',
}

export interface WalletConfirmation {
  wallet: WalletResponseInterface;
  confirmation: boolean;
  errors: any;
}

@Injectable({
  providedIn: 'root',
})
export class WalletCreatorService {
  private walletCreated$ = this.walletsService
    .getObservable(WalletSections.CREATE_WALLET)
    .pipe(
      filter((t) => !!t),
      map((t) => t[0]),
      first(),
    );

  private maxRetries = 2;
  private retryPeriod = 30; // 30 seconds

  private waitingWallet = new BehaviorSubject<boolean>(false);
  waitingWallet$ = this.waitingWallet.asObservable();

  constructor(
    private PMService: PMService,
    private walletsService: WalletsService,
    private authService: AuthService,
  ) {}

  private sendWallet(wallet: WalletResponseInterface): Observable<boolean> {
    return this.PMService.send(PMMessageTypes.NEW_WALLET, wallet, {
      maxRetries: this.maxRetries,
      retryPeriod: this.retryPeriod * 1000,
    });
  }

  deleteWallet(walletId: string): void {
    const { id: userId } = this.authService.currentUser;
    this.walletsService.delete(walletId, [userId]);
  }

  createWalletAndNotify(assetId: string): Observable<WalletConfirmation> {
    const { id: userId } = this.authService.currentUser;

    if (this.PMService.status !== PMStatus.READY) {
      throw new Error(PMLiterals.CANT_CREATE_WALLET);
    }

    this.walletsService.cleanSection(WalletSections.CREATE_WALLET);

    this.walletsService.create({ assetId }, WalletSections.CREATE_WALLET, [
      userId,
    ]);

    this.waitingWallet.next(true);

    return this.walletCreated$.pipe(
      switchMap((wallet) =>
        combineLatest({
          wallet: of(wallet),
          confirmation: this.sendWallet(wallet).pipe(startWith(null)),
          errors: this.PMService.listen(PMMessageTypes.ERROR).pipe(
            startWith(null),
          ),
        }),
      ),
      filter(
        ({ wallet, confirmation, errors }) =>
          !!errors || (!!wallet && confirmation !== null),
      ),
      tap(({ wallet, confirmation, errors }) => {
        if (errors || !confirmation) {
          this.PMService.log(
            `🟪 Wallet [ID=${wallet.id}] Not confirmed by Creand...`,
          );
          this.PMService.log(`🗑 Wallet [ID=${wallet.id}] Deleted.`);
          this.deleteWallet(wallet.id);
        }
        this.waitingWallet.next(false);
      }),
      first(),
    );
  }
}
