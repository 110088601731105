import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import * as Sentry from '@sentry/browser';
// import * as packageJson from '../../package.json';

const sentryConf = Object.assign({}, environment.sentry, {
  environment: environment.environment,
  release: 'dev',
});

Sentry.init(sentryConf);

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    try {
      Sentry.captureException(error.originalError || error);
    } catch (e) {
      console.error(e);
    }
    throw error;
  }
}
