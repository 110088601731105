import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface BasicObservable<Payload = any, Id = string> {
  id?: Id;
  payload?: Payload;
}

@Injectable()
export class BasicObservableService {
  private dataStore: {
    standard: BasicObservable[];
  };
  private observables: {
    standard: BehaviorSubject<BasicObservable[]>;
  };
  private behaviorSubjects: {
    standard: Observable<BasicObservable[]>;
  };

  constructor() {
    this.dataStore = {
      standard: [] as BasicObservable[],
    };
    this.observables = {
      standard: new BehaviorSubject([]) as BehaviorSubject<BasicObservable[]>,
    };
    this.behaviorSubjects = {
      standard: this.observables.standard.asObservable(),
    };
  }

  private checkSection(section = '+', forze = false) {
    if (forze || !this.dataStore[section]) {
      this.dataStore[section] = [] as BasicObservable[];
      this.observables[section] = new BehaviorSubject(null) as BehaviorSubject<
        BasicObservable[]
      >;
      this.behaviorSubjects[section] = this.observables[section].asObservable();

      const subSections = section.replace(/\.([^\.])*$/gi, '');
      if (subSections !== section) {
        this.checkSection(subSections);
      }
    }
    return true;
  }

  getObservable(section = 'standard'): Observable<BasicObservable[]> {
    this.checkSection(section);
    return this.behaviorSubjects[section];
  }

  cleanSection(section = 'standard'): void {
    this.checkSection(section);
    this.dataStore[section] = [] as BasicObservable[];
    this.observables[section].next(Object.assign({}, this.dataStore)[section]);
  }

  create(basicObservable: BasicObservable, section = 'standard'): void {
    this.checkSection(section);
    this.dataStore[section].push(basicObservable);
    this.observables[section].next(Object.assign({}, this.dataStore)[section]);

    const subSections = section.replace(/\.([^\.])*$/gi, '');
    if (subSections !== section) {
      this.create(basicObservable, subSections);
    }
  }

  update(
    basicObservable: BasicObservable,
    addToSection: string = null,
    key: string = 'id',
  ): void {
    let added = false;

    Object.keys(this.dataStore).forEach((section) => {
      let dataInSection = false;
      this.dataStore[section].forEach((t, i) => {
        if (t[key] === basicObservable[key]) {
          if (addToSection === section) {
            added = true;
          }
          dataInSection = true;

          this.dataStore[section][i] = basicObservable;
        }
      });

      if (dataInSection) {
        this.observables[section].next(
          Object.assign({}, this.dataStore)[section],
        );
      }
    });

    // If it's not added, add it.
    if (!added && addToSection !== null) {
      this.create(basicObservable, addToSection);
    }
  }

  remove(id: string, key = 'id'): void {
    Object.keys(this.dataStore).forEach((section) => {
      this.dataStore[section].forEach((t, i) => {
        if (t[key] === id) {
          this.dataStore[section].splice(i, 1);
        }
      });

      this.observables[section].next(
        Object.assign({}, this.dataStore)[section],
      );
    });
  }
}
