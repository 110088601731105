import { Injectable } from '@angular/core';
import { AbstractService } from '@services/abstract/abstract.service';
import { AssetStatsRatesInterface } from '@interfaces/stats/asset-stats-rates.interface'
import { AssetStatsRatesQueryParams } from '@interfaces/stats/asset-stats-rates.params'

@Injectable()
export class AssetStatsRatesService extends AbstractService<
  AssetStatsRatesInterface,
  AssetStatsRatesQueryParams
> {
  protected errorsSection = 'errors.asset-stats-rates';
  protected endPoint = '/assets/%s/rates';
}
