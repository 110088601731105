import { Injectable } from '@angular/core';
import {
  BasicObservable,
  BasicObservableService,
} from '@services/basic-observable/basic-observable.service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Action } from './action.interface';

@Injectable()
export class ActionObservableService {
  private readonly section = 'ACTIONS';
  private readonly id = 'ACTION';

  constructor(private readonly basicObservableService: BasicObservableService) {
    this.createAction();
  }

  getObservable<Key = string, Payload = unknown>(): Observable<
    Action<Key, Payload>
  > {
    return this.basicObservableService.getObservable(this.section).pipe(
      // filter((actions: BasicObservable[]) => actions.length > 0),
      map((actions: BasicObservable[]) =>
        this.mapActions<Key, Payload>(actions),
      ),
      filter((action) => !!action),
    );
  }

  setAction<Key = string, Payload = unknown>(key: Key, payload?: Payload) {
    const basicOb: BasicObservable<Action<Key, Payload>> = {
      id: this.id,
      payload: {
        key,
        payload,
      },
    };
    this.basicObservableService.update(basicOb, this.section);
  }

  private createAction() {
    const basicOb: BasicObservable<Action> = {
      id: this.id,
    };
    this.basicObservableService.create(basicOb, this.section);
  }

  private mapActions<Key = string, Payload = unknown>(
    actions: BasicObservable<Action<Key, Payload>>[],
  ): Action<Key, Payload> {
    return actions[0]?.payload;
  }
}
