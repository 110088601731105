import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface SessionStorage {
  key: string;
  value: any;
}

@Injectable()
export class SessionStorageService {
  private dataStore: {
    standard: any;
  };
  private observables: {
    standard: BehaviorSubject<any>;
  };
  private behaviorSubjects: {
    standard: Observable<any>;
  };

  constructor() {
    this.dataStore = {
      standard: {} as any,
    };
    this.observables = {
      standard: new BehaviorSubject({} as any) as BehaviorSubject<any>,
    };
    this.behaviorSubjects = {
      standard: this.observables.standard.asObservable(),
    };
  }

  private checkSection(section = 'standard', forze = false) {
    if (forze || !this.dataStore[section]) {
      this.dataStore[section] = {} as any;
      this.observables[section] = new BehaviorSubject(
        {} as any,
      ) as BehaviorSubject<any>;
      this.behaviorSubjects[section] = this.observables[section].asObservable();
    }
    return true;
  }

  getObservable(section = 'standard'): Observable<any> {
    this.checkSection(section);
    return this.behaviorSubjects[section];
  }

  cleanSection(section = 'standard') {
    this.checkSection(section);
    this.dataStore[section] = {};
    this.observables[section].next(Object.assign({}, this.dataStore)[section]);
  }

  load(key: string, section = 'standard') {
    this.checkSection(section);
    const data = sessionStorage.getItem(key);
    this.dataStore[section][key] = JSON.parse(data);
    this.observables[section].next(Object.assign({}, this.dataStore)[section]);
  }

  create(element: SessionStorage, section = 'standard') {
    this.checkSection(section);
    sessionStorage.setItem(element.key, JSON.stringify(element.value));
    this.dataStore[section][element.key] = element.value;
    this.observables[section].next(Object.assign({}, this.dataStore)[section]);
  }

  update(element: SessionStorage) {
    Object.keys(this.dataStore).forEach((section) => {
      if (this.dataStore[section][element.key]) {
        this.dataStore[section][element.key] = element.value;
        sessionStorage.setItem(element.key, JSON.stringify(element.value));
        this.observables[section].next(
          Object.assign({}, this.dataStore)[section],
        );
      }
    });
  }

  remove(key: string) {
    Object.keys(this.dataStore).forEach((section) => {
      if (this.dataStore[section][key]) {
        delete this.dataStore[section][key];
        sessionStorage.removeItem(key);
        this.observables[section].next(
          Object.assign({}, this.dataStore)[section],
        );
      }
    });
  }
}
