import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthHttpInterceptor } from '../auth/auth.http.interceptor';

// services
import {
  ConfigurationsPurchaseService,
  ConfigurationsWithdrawalService,
} from './configurations.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
})
export class ConfigurationsServiceModule {
  static forRoot(): ModuleWithProviders<ConfigurationsServiceModule> {
    return {
      ngModule: ConfigurationsServiceModule,
      providers: [
        ConfigurationsPurchaseService,
        ConfigurationsWithdrawalService,
      ],
    };
  }
}
