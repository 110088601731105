import { Injectable } from '@angular/core';
import { StatsQueryParamsInterface } from '@interfaces/stats/stats.query.params.interface';
import { StatsResponseInterface } from '@interfaces/stats/stats.response.interface';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class StatsService extends AbstractService<
  StatsResponseInterface,
  StatsQueryParamsInterface
> {
  protected errorsSection = 'errors.stats';
  protected endPoint = '/stats/';
}
