import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  filter,
  first,
  fromEvent,
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
  timer,
} from 'rxjs';
import {
  PMConfig,
  PMConfigParams,
  PMDefaultConfig,
  PMErrors,
  PMEvent,
  PMLiterals,
  PMMessage,
  PMMessageTypes,
  PMStatus,
} from './pm.models';

@Injectable({
  providedIn: 'root',
})
export class PMService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly status$ = new BehaviorSubject(
    PMStatus.INITIALIZING,
  ) as BehaviorSubject<PMStatus>;
  private readonly messageListener$ = fromEvent<PMEvent<any>>(
    window,
    'message',
  ).pipe(
    takeUntil(this.destroy$),
    map((messageEvent) => ({
      origin: messageEvent.origin,
      source: messageEvent.source,
      data: messageEvent.data,
    })),
  );

  config: PMConfig;
  status: PMStatus;

  private receiver = window.parent;
  private targetOrigin: string;
  private isTargetAuto: boolean;

  constructor(@Inject(PMConfigParams) config: PMConfig) {
    this.config = { ...PMDefaultConfig, ...config };
    this.initPMService();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private initPMService() {
    //this.setStatus(PMStatus.INITIALIZING);
    this.isTargetAuto = this.config.targetOrigin === 'auto';

    if (this.config.autoConnect) {
      this.connect().subscribe();
    }
  }

  private setStatus(status: PMStatus): void {
    this.status = status;
    this.status$.next(status);
  }

  listen<T>(messageType: PMMessageTypes): Observable<PMEvent<T>> {
    const sender = window.parent;
    return this.messageListener$.pipe(
      filter(
        (messageEvent) =>
          messageEvent.source === sender &&
          messageEvent.data?.operation === messageType,
      ),
      tap((data) => this.log(PMLiterals.MSG_FROM_PARENT, data.data)),
    );
  }

  connect(): Observable<boolean> {
    this.status$.next(PMStatus.CONNECTING);
    this._sendMessage(PMMessageTypes.PING);

    return this.waitForMessage$<PMMessage<null>>(PMMessageTypes.PONG).pipe(
      map((pingPong) => {
        if (!pingPong) {
          this.sendError(PMErrors.PONG);
          this.log(PMLiterals.ERROR_PONG_NOT_COMPLETED);
          return false;
        }

        if (this.isTargetAuto) {
          this.log(PMLiterals.AUTO_ORIGIN, pingPong.origin);
          this.targetOrigin = pingPong.origin;
        }

        this.log(PMLiterals.PINGPONG_COMPLETED);
        this.setStatus(PMStatus.READY);
        return !!pingPong;
      }),
      first(),
    );
  }

  send<T>(
    channel: PMMessageTypes,
    data?: T,
    customConfig?: Partial<PMConfig>,
  ): Observable<boolean> {
    const options = {
      ...this.config,
      ...customConfig,
    };

    return this.status$.pipe(
      switchMap((status) => {
        if (status !== PMStatus.READY && options.waitForReady) {
          this.error(PMLiterals.ERROR_MSG_NO_SENT_PINGPONG, data);
          return EMPTY;
        }

        this._sendMessage(channel, data);

        if (options.waitForAck) {
          return this.waitForMessage$<PMMessage<null>>(
            PMMessageTypes.ACK,
            customConfig,
          ).pipe(
            map((ackStatus) => {
              const ackSuccess = !!ackStatus;

              if (ackSuccess) {
                this.log(PMLiterals.MSG_ACK_CONFIRMED, data);
              } else {
                this.error(PMLiterals.ERROR_NO_ACK, data);
              }

              return ackSuccess;
            }),
          );
        } else {
          return EMPTY;
        }
      }),
      first(),
    );
  }

  private waitForMessage$<T>(
    messageType: PMMessageTypes,
    customConfig?: Partial<PMConfig>,
  ): Observable<PMEvent<T>> {
    const options = {
      ...this.config,
      ...customConfig,
    };

    let messageSent = false;
    return combineLatest([
      timer(0, options.retryPeriod).pipe(
        takeWhile((timer) => timer <= options.maxRetries - 1),
      ),
      this.listen<T>(messageType).pipe(startWith(null)),
    ]).pipe(
      takeWhile(() => !messageSent),
      switchMap(([timer, listener]) => {
        if (!!listener?.data) {
          messageSent = true;
          return of(listener);
        }

        const keepWaiting = timer < options.maxRetries - 1;
        return keepWaiting ? EMPTY : of(null);
      }),
      first(),
    );
  }

  public _sendMessage<T>(operation: PMMessageTypes, data?: T): void {
    this.log(PMLiterals.MSG_SENT_PARENT, { operation, data });

    // Enviamos el mensaje a TODOS los dominios solo SI:
    // - No se ha completado el ping pong
    // - Es un mensaje de error (y posiblemente sea del ping pong fallido)
    const anyDomain =
      (this.config.targetOrigin === 'auto' &&
        operation === PMMessageTypes.PING) ||
      operation === PMMessageTypes.ERROR;

    const origin = anyDomain ? '*' : this.targetOrigin;
    this.receiver.postMessage({ operation, data }, origin);
  }

  log(message: string, ...args: any): void {
    if (this.config.debug) console.log('[IFRAME]', message, ...args);
  }

  error(message: string, ...args: any): void {
    if (this.config.debug) console.error('[IFRAME]', message, ...args);
  }

  sendError(error: PMErrors): void {
    this.send(PMMessageTypes.ERROR, error, {
      waitForAck: false,
      waitForReady: false,
    }).subscribe();
  }
}
