import { Injectable } from '@angular/core';
import { TransactionQueryParamsInterface } from '@interfaces/user/wallet/transaction/transaction.query.params.interface';
import { TransactionResponseInterface } from '@interfaces/user/wallet/transaction/transaction.response.interface';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class TransactionsService extends AbstractService<
  TransactionResponseInterface,
  TransactionQueryParamsInterface
> {
  protected errorsSection = 'errors.transactions';
  protected endPoint = '/users/%s/wallets/%s/transactions/';
}
