import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

// services
import { AuthHttpInterceptor } from '../auth/auth.http.interceptor';
import { AlertsService } from './alerts.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
})
export class AlertsServiceModule {
  static forRoot(): ModuleWithProviders<AlertsServiceModule> {
    return {
      ngModule: AlertsServiceModule,
      providers: [AlertsService],
    };
  }
}
