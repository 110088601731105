import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ToastController } from '@ionic/angular';
import { ToastOptions } from '@ionic/core';

@Injectable()
export class IonicToasterService {
  public message: string;
  private toast: any;
  private options: ToastOptions;

  constructor(public toasterIonic: ToastController) {
    this.options = environment.toaster;
  }

  async presentToastWithOptions(
    message: string,
    className?: string,
    externalOpts?: ToastOptions,
  ) {
    const opts = externalOpts ? externalOpts : this.options;
    opts.message = message;
    opts.cssClass = className;

    this.toast = await this.toasterIonic.create(opts);
    this.toast.present();
  }
}
