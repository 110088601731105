import { CommonModule } from '@angular/common';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { NgModule } from '@angular/core';
import { IonicToasterService } from './ionic-toaster.service';

@NgModule({
  imports: [CommonModule],
})
export class IonicToasterModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: IonicToasterModule,
      providers: [IonicToasterService],
    };
  }
}
