import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Device } from '@ionic-native/device/ngx';
import { FCM } from '@ionic-native/fcm/ngx';
import { KeychainTouchId } from '@ionic-native/keychain-touch-id/ngx';
import { AuthHttpInterceptor } from '@services/auth/auth.http.interceptor';
import { BasicObservableModule } from '@services/basic-observable/basic-observable.module';
import { DeviceService } from './device.service';

@NgModule({
  imports: [HttpClientModule, BasicObservableModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
})
export class DeviceServiceModule {
  static forRoot(): ModuleWithProviders<DeviceServiceModule> {
    return {
      ngModule: DeviceServiceModule,
      providers: [DeviceService, Device, FCM, AppVersion, KeychainTouchId],
    };
  }
}
