import { Injectable } from '@angular/core';
import { UserQueryParamsInterface } from '@interfaces/user/user.query.params.interface';
import { UserResponseInterface } from '@interfaces/user/user.response.interface';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class UsersService extends AbstractService<
  UserResponseInterface,
  UserQueryParamsInterface
> {
  protected errorsSection = 'errors.user';
  protected endPoint = '/users/';
}
