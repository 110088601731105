import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@environments/environment';
import { UserResponseInterface } from '@interfaces/user/user.response.interface';
import { WalletResponseInterface } from '@interfaces/user/wallet/wallet.response.interface';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import { BasicObservableService } from '@services/basic-observable/basic-observable.service';
import { WalletsService } from '@services/users/wallets/wallets.service';
import { lastValueFrom } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-sso',
  templateUrl: 'sso.component.html',
})
export class SsoComponent implements OnInit {
  private routeParams$ = this.route.queryParams.pipe(take(1));

  private onAuth$ = this.authService.getObservable().pipe(
    filter((user: UserResponseInterface) => !!user),
    take(1),
  );
  private onWallets$ = this.walletService.getObservable().pipe(
    filter((wallets: WalletResponseInterface[]) => !!wallets),
    take(1),
  );

  languages = environment.languages;

  constructor(
    private readonly authService: AuthService,
    private readonly walletService: WalletsService,
    private readonly route: ActivatedRoute,
    private readonly navCtrl: NavController,
    private readonly translateService: TranslateService,
    private readonly bOBService: BasicObservableService,
  ) {}

  public ngOnInit(): void {
    this.initParams();
  }

  async initParams(): Promise<void> {
    const params = await lastValueFrom(this.routeParams$);

    if (params?.lang) {
      const lang = this.languages.find((l) => l === params.lang) ?? 'es';
      this.translateService.setDefaultLang(lang);
      this.translateService.use(lang);
    }

    if (params?.sso) {
      this.authService.logout();
      this.authService.refresh(false, params.sso);
      await this.redirectOnAuth(params.goto);
    }
  }

  private async redirectOnAuth(to: string): Promise<void> {
    const user = await lastValueFrom(this.onAuth$);
    let nextUrl = '/admin';

    if (to) {
      const wallets = await this.getWallets(user.id);
      const wallet = wallets.find((wallet) => wallet.asset.symbol === to);
      if (wallet) nextUrl = `/admin/wallets/${wallet.id}`;
    }

    this.navigate(nextUrl);
  }

  private async getWallets(userId: string): Promise<WalletResponseInterface[]> {
    this.walletService.getAll({}, 'standard', [userId]);
    return lastValueFrom(this.onWallets$);
  }

  private navigate(path: string) {
    this.navCtrl.navigateRoot(path);
  }
}
