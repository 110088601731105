import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

// services
import { environment } from '@environments/environment';
import { IonicStorageModule } from '@ionic/storage';
import { LocalStorageService } from './local-storage.service';

@NgModule({
  imports: [CommonModule, IonicStorageModule.forRoot(environment.storageDB)],
})
export class LocalStorageModule {
  static forRoot(): ModuleWithProviders<LocalStorageModule> {
    return {
      ngModule: LocalStorageModule,
      providers: [LocalStorageService],
    };
  }
}
