import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

// services
import { SessionStorageService } from './session-storage.service';

@NgModule({
  imports: [CommonModule],
})
export class SessionStorageModule {
  static forRoot(): ModuleWithProviders<SessionStorageModule> {
    return {
      ngModule: SessionStorageModule,
      providers: [SessionStorageService],
    };
  }
}
