import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { UserResponseInterface } from '@interfaces/user/user.response.interface';
import { NavController } from '@ionic/angular';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { Observable, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

@Injectable()
export class NoLocalUserGuard implements CanActivate, CanActivateChild {
  constructor(
    protected router: Router,
    protected navCtrl: NavController,
    protected storage: LocalStorageService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.canActivateChild(route, state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.storage.getObservable().pipe(
      filter((store: any) => !!store),
      map((store: any) => {
        if (store.localUser?.id) {
          this.navCtrl.navigateRoot('/public/login');
          return false;
        }
        return true;
      }),
      catchError((err) => {
        return of(true);
      }),
    );
  }
}
