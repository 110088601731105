import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IonicToasterService } from '@ionServices/toaster/ionic-toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { AbstractAuthService } from '@services/abstract/abstract.auth.service';
import { ErrorsService } from '@services/errors/errors.service';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { SessionStorageService } from '@services/session-storage/session-storage.service';
import { AuthService as SocialAuthService } from 'ng2-ui-auth';
import { BasicObservableService } from '../basic-observable/basic-observable.service';

@Injectable()
export class AuthService extends AbstractAuthService {
  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService,
    protected storage: LocalStorageService,
    protected session: SessionStorageService,
    protected socialAuth: SocialAuthService,
    protected basicObservable: BasicObservableService,
    protected toasterIonic: IonicToasterService,
    protected translate: TranslateService,
  ) {
    super(
      http,
      errorsService,
      storage,
      session,
      basicObservable,
      toasterIonic,
      translate,
    );
  }

  public async loginLinkedIn() {
    this.socialAuth.authenticate('linkedin').subscribe({
      next: (v) => this.loginSuccess(v),
      error: (e) => this.loginError(e),
    });
  }

  public async loginFacebook() {
    this.socialAuth.authenticate('facebook').subscribe({
      next: (v) => this.loginSuccess(v),
      error: (e) => this.loginError(e),
    });
  }

  public async loginGoogle() {
    this.socialAuth.authenticate('google').subscribe({
      next: (v) => this.loginSuccess(v),
      error: (e) => this.loginError(e),
    });
  }
}
