import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthHttpInterceptor } from '../auth/auth.http.interceptor';
import { ConfigsService } from './config.service';

@NgModule({})
export class ConfigsServiceModule {
  static forRoot(): ModuleWithProviders<ConfigsServiceModule> {
    return {
      ngModule: ConfigsServiceModule,
      providers: [ConfigsService],
    };
  }
}
