import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { BasicObservableService } from '../basic-observable/basic-observable.service';
import { Observable, filter, map, take } from 'rxjs';

export const ConfigSection = 'actual-config';

@Injectable()
export class ConfigsService {

  public config$ = this.bOBService.getObservable(ConfigSection).pipe(
    filter((t) => !!t),
    map((t) => t[t.length - 1].payload as typeof environment)
  )

  constructor(protected bOBService: BasicObservableService) {
    const basicOb = {
      hash: ConfigSection,
      payload: environment,
    };
    this.bOBService.create(basicOb, basicOb.hash);
  }

  getProp$<T>(key: keyof typeof environment): Observable<T> {
    return this.config$.pipe(
      map((t) => t[key] as T)
    )
  }

  update(props: Partial<typeof environment>): void {
    this.config$.pipe(take(1)).subscribe(
      config => {
        this.bOBService.update(
          {
            id: ConfigSection,
            payload: {...config, ...props},
          },
          ConfigSection
        )
      }
    )
  }
}
