import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthHttpInterceptor } from '../auth/auth.http.interceptor';

// services
import { AssetStatsService } from './asset-stats.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
})
export class AssetStatsServiceModule {
  static forRoot(): ModuleWithProviders<AssetStatsServiceModule> {
    return {
      ngModule: AssetStatsServiceModule,
      providers: [AssetStatsService],
    };
  }
}
