import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthHttpInterceptor } from '../auth/auth.http.interceptor';

// services
import { WalletCreatorService } from './wallet-creator.service';
import { PMService } from '@services/pm-service/pm.service';

@NgModule()
export class WalletCreatorServiceModule {
  static forRoot(): ModuleWithProviders<WalletCreatorServiceModule> {
    return {
      ngModule: WalletCreatorServiceModule,
      providers: [WalletCreatorService],
    };
  }
}
