import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserResponseInterface } from '@interfaces/user/user.response.interface';
import { NavController } from '@ionic/angular';
import { AbstractAuthGuard } from '@services/abstract/abstract.auth.guard';
import { AuthService } from '@services/auth/auth.service';

@Injectable()
export class NoUserGuard extends AbstractAuthGuard {
  constructor(
    protected authService: AuthService,
    protected router: Router,
    protected navCtrl: NavController,
  ) {
    super(authService, router);
  }

  protected check(
    user: UserResponseInterface,
    route: ActivatedRouteSnapshot,
  ): boolean {
    if (user) {
      this.navCtrl.navigateRoot('/admin');
      return false;
    }

    return true;
  }

  protected onError(e): boolean {
    return false;
  }
}
