import { Injectable } from '@angular/core';
import { AlertQueryParamsInterface } from '@interfaces/alert/alert.query.params.interface';
import { AlertResponseInterface } from '@interfaces/alert/alert.response.interface';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class AlertsService extends AbstractService<
  AlertResponseInterface,
  AlertQueryParamsInterface
> {
  protected errorsSection = 'errors.alert';
  protected endPoint = '/alerts/';
}
