import { ModuleWithProviders, NgModule } from '@angular/core';
import { PMConfig, PMConfigParams, PMDefaultConfig } from './pm.models';
import { PMService } from './pm.service';

@NgModule({
  providers: [PMService],
})
export class PMServiceModule {
  static forRoot(config?: PMConfig): ModuleWithProviders<PMServiceModule> {
    return {
      ngModule: PMServiceModule,
      providers: [
        {
          provide: PMConfigParams,
          useValue: config || PMDefaultConfig,
        },
        PMService,
      ],
    };
  }
}
