import { ModuleWithProviders, NgModule } from '@angular/core';
import { OperationsCreatorService } from './operations.service';

@NgModule()
export class OperationsCreatorServiceModule {
  static forRoot(): ModuleWithProviders<OperationsCreatorServiceModule> {
    return {
      ngModule: OperationsCreatorServiceModule,
      providers: [OperationsCreatorService],
    };
  }
}
