import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthModule } from '@services/auth/auth.module';
import { CompletedUserGuard } from './completed.user.guard';
import { NoLocalUserGuard } from './no.local.user.guard';
import { NoUserGuard } from './no.user.guard';
import { UnCompletedUserGuard } from './uncompleted.user.guard';
import { UserGuard } from './user.guard';

@NgModule({
  imports: [CommonModule, AuthModule],
})
export class GuardModule {
  static forRoot(): ModuleWithProviders<GuardModule> {
    return {
      ngModule: GuardModule,
      providers: [
        UserGuard,
        NoUserGuard,
        CompletedUserGuard,
        UnCompletedUserGuard,
        NoLocalUserGuard,
      ],
    };
  }
}
